// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t_o8toANOB75hC1XSZcA{font-family:sans-serif;background:black;background-size:cover;display:flex;flex-direction:column;min-height:100vh}.sKe9eZ8u9tSOttyEmrIQ{align-items:center;display:flex;flex-grow:1;padding:1em}.RWQO1QcahMPjKfhU1kKA{background-color:#000;color:#fff;flex-grow:0}`, "",{"version":3,"sources":["webpack://./src/styles/swagpage.module.css"],"names":[],"mappings":"AAAA,sBACE,sBAAA,CACA,gBAAA,CACA,qBAAA,CACA,YAAA,CACA,qBAAA,CACA,gBAAA,CAGF,sBACE,kBAAA,CACA,YAAA,CACA,WAAA,CACA,WAAA,CAGF,sBACE,qBAAA,CACA,UAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
export var background = `t_o8toANOB75hC1XSZcA`;
export var main = `sKe9eZ8u9tSOttyEmrIQ`;
export var footer = `RWQO1QcahMPjKfhU1kKA`;
export default ___CSS_LOADER_EXPORT___;
