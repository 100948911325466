// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uV5dRi76s8xLo_eKoFuN{background-color:#fff;border-radius:.5em;margin:0 auto;padding:1em;max-width:400px;min-width:300px;text-align:center;width:50vw}.uV5dRi76s8xLo_eKoFuN a{text-decoration:none}.uV5dRi76s8xLo_eKoFuN a:hover{text-decoration:underline}.hNUW4NdwEDZjLzidWN5U{border:solid 1px #ccc;border-radius:.5em;display:block;font-size:1.2em;margin:auto;padding:.4em;text-align:center;text-transform:uppercase;width:100%}.hNUW4NdwEDZjLzidWN5U::placeholder{color:#ccc;opacity:1}`, "",{"version":3,"sources":["webpack://./src/styles/code-entry.module.css"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,kBAAA,CACA,aAAA,CACA,WAAA,CACA,eAAA,CACA,eAAA,CACA,iBAAA,CACA,UAAA,CAGF,wBACE,oBAAA,CAGF,8BACE,yBAAA,CAGF,sBACE,qBAAA,CACA,kBAAA,CACA,aAAA,CACA,eAAA,CACA,WAAA,CACA,YAAA,CACA,iBAAA,CACA,wBAAA,CACA,UAAA,CAGF,mCACE,UAAA,CACA,SAAA","sourceRoot":""}]);
// Exports
export var codeEntry = `uV5dRi76s8xLo_eKoFuN`;
export var codeInput = `hNUW4NdwEDZjLzidWN5U`;
export default ___CSS_LOADER_EXPORT___;
